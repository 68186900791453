import '../helpers/helpers';

import * as pagepiling from 'pagepiling';

var jQueryBridget = require('jquery-bridget');
var Isotope = require('isotope-layout');
jQueryBridget( 'isotope', Isotope, $ );

// reorganize isotope
function reorganizeIsotope() { 
	$('.isotope-grid[class*="style-modern"]').each(function(){
		var $container = $(this);
		var width = $container.find(".grid-sizer").width();
		var ratioheight = $container.data('heightratio');
		if (!ratioheight) { ratioheight = 0.8; }
		var spacing = 0; if ($container.hasClass("isotope-spaced") || $container.hasClass("isotope-spaced-mini")) { spacing = parseInt($container.find(".isotope-item").css('marginRight'),10); }
		var height = parseInt(width * ratioheight, 10);
		$container.children('.isotope-item').css({ 'height': height+'px' });
		$container.children('.isotope-item.tall, .isotope-item.wide-tall').css({ 'height': height*2+spacing+'px' });
		$container.isotope('layout');

		// adapt images
		$container.children('.isotope-item').each(function(i) {
			var imgHeight = $(this).find("img").height();
           	var imgWidth = $(this).find("img").width();
			var imgRatio = imgWidth/imgHeight;
			var itemHeight = $(this).height();
           	var itemWidth = $(this).width();
			var itemRatio = itemWidth/itemHeight;
			var imgClass = '';
			if (imgRatio < itemRatio) { imgClass = 'wide-img'; $(this).find("img").removeClass('tall-img'); }
			else { imgClass = 'tall-img'; $(this).find("img").removeClass('wide-img'); }
			$(this).find("img").addClass(imgClass);

			if(i > 7) {
				$(this).addClass('portfolio-item--hidden-on-start');
			}
        });
	});
}

document.addEventListener('DOMContentLoaded', function(){

	function toggleOverlay() {
		var overlay = $('.overlay');

		if(overlay.hasClass('open')) {
			overlay.removeClass('open');
			overlay.addClass('close');

			setTimeout(function(){
				overlay.removeClass('close');
			}, 250);
		} else if(!overlay.hasClass('close')) {
			overlay.addClass('open');
		}
	}

	var $fancybox = $('a.fancybox');

	$('.trigger-btn, .overlay-close').on('click', toggleOverlay);

	if($fancybox.length) {
		$fancybox.fancybox({
			helpers: {
				overlay: {
					locked: false
				}
			}
		});
	}
	if($(window).width() >= 768) {
		if($('body').hasClass('home')) {
			$('body').addClass('pagepilling');

			$('#holder').pagepiling({
			    navigation: {
			    	'position': 'right',
			   		'tooltips': [$('.section--1').attr('data-title'), $('.section--2').attr('data-title'), $('.section--3').attr('data-title'), $('.section--4').attr('data-title')]
			   	},
			   	loopBottom: true,
			});
		} else if($('.single-section-prod').length){
			$('body').addClass('pagepilling');
			
			$('#holder').pagepiling({
				loopBottom: true
			});
		}
	}

});


$(window).load(function() {
	if( $('.isotope-grid').length ) {
		$('.isotope-grid').each(function(){
			var $container = $(this);
			$(this).prepend('<div class="grid-sizer"></div>');
			if (!$container.hasClass("fitrows")) { 
				$container.isotope({
					layoutMode: 'masonry',
					itemSelector : '.isotope-item',
					masonry: { columnWidth: '.grid-sizer' }
				});	
			} else {
				$container.isotope({
					layoutMode: 'fitRows',
					itemSelector : '.isotope-item',
					masonry: { columnWidth: '.grid-sizer' }
				});	
			}
		});
			
		$('.filter').on("click", "li a", function() { 
			var thisItem = $(this),
				parentul = thisItem.parents('ul.filter').data('related-grid'),
				dataCategory = thisItem.attr('data-cat');

			if (!parentul) {
				alert('Please specify the data-related-grid');
			} else {
				thisItem.parents('ul.filter').find('a').removeClass('active');
				thisItem.addClass('active');
				var selector = thisItem.attr('data-filter');
				$('#'+parentul).isotope({ filter: selector }, function(){ });
				var elems = $('#'+parentul).isotope('getFilteredItemElements');

			}
			
			return false;
		});
		
		reorganizeIsotope();

		if($('.isotope-grid').length) {
			setTimeout(function(){
				$('.isotope-grid').isotope('layout');
			}, 0);
		}
		
	}
});

$(window).resize(function() { 
	if( $('.isotope-grid').length ) { reorganizeIsotope(); }
});